<template>
  <div class="shopping_box">
     <div class="shopping_list">
        <van-swipe-cell v-for="(item, index) in list" :key="index" v-if="list.length > 0">
            <div class="shopping_s" @click="changeVal(index)" >
                <van-checkbox v-model="item.checked" checked-color="#000"></van-checkbox>
                <van-card
                    :num="item.number"
                    :price="item.price"
                    :title="item.productName"
                    class="goods-card"
                    :thumb="item.pic"
                >
                <template #tags>
                    <van-tag v-for="(itemsku, indexsku) in item.skuName.split(';')" :key="indexsku" color="#F6F7FA" text-color="#939297">{{itemsku}}&nbsp; <img src="../../../../src/assets/image/brake.png" alt=""></van-tag>
                </template>
                </van-card>
            </div>
            <template #right>
                <van-button square text="删除" type="danger" class="delete-button" />
            </template>
        </van-swipe-cell>
        <van-empty description="去挑选商品吧~"  v-if="list.length == 0"/>
     </div>
     <!-- 猜你喜欢 -->
     <div class="shop_title">
         <img class="star_left" src="../../../../src/assets/image/star.png">
         <img class="star_left"  src="../../../../src/assets/image/star.png">
         <span>猜你喜欢</span>
         <img class="star_right"  src="../../../../src/assets/image/star.png">
         <img class="star_right"  src="../../../../src/assets/image/star.png">
     </div>
     <!-- 商品 瀑布流 -->
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            v-if="likelist.length > 0"
        >
    <div class="lazy_box">
         <van-cell @click="goGoodDetails(item.id)" v-for="(item, index) in likelist" :key="index">
            <div class="item" >
                <img  :src="item.topPic" alt="" />
                <div class="intro">{{item.name}}</div>
                <div class="jiamu">
                    <div class="price">￥{{item.price}}</div>
                    <div class="stock"></div>
                </div>
            </div>
         </van-cell>
    </div>
      </van-list>
    <div v-if="likelist.length == 0">
    <van-empty description="暂无推荐喜欢商品" />
</div>
  <div class="bottom_list">
    <bottomList></bottomList>
  </div>
    <!-- end -->
    <div class="operating">
        <div class="op_left">
            <van-checkbox v-model="checked" checked-color="#000" @click="checkAll">全选</van-checkbox>
            <span class="p_num">￥{{totalPrice}}</span>
        </div>
        <div class="op_right" @click="doBuy">
            <span>去结算({{checkedNum}})</span>
        </div>
    </div>
    <div class="menu">
      <menuBottom></menuBottom>
    </div>
    <balance v-if="isBuyNew" ref="buy" :skuparams="payParams"/>
    <addBind v-if="addBindShow"/>
    <codeBind v-if="codeBindShow" :obj="codeObj" :id="codeId"/>
    <codeBindBuygwc :obj="payObj" v-if="isBindBuy" ref="bindbuy"></codeBindBuygwc>
  </div>
</template>

<script>
import menuBottom from '@/views/components/business/menuBottom'
import balance from '@/views/components/business/balance'
import addBind from '@/views/components/business/addBind'
import codeBind from '@/views/components/business/codeBind'
import codeBindBuygwc from '@/views/components/business/codeBindBuygwc'
import { getToken } from '@/utils/auth'
import { cartList, likeList } from '@/api/mallPage'
import { getMrAddress } from '@/api/order' // 获取默认地址
export default {
  name: "shoppingIndex",
  components: {
    menuBottom,
    balance,
    addBind,
    codeBind,
    codeBindBuygwc
  },
  data() {
    return {
        payObj: {},
        codeObj: {},
        codeId: 0,
        addBindShow: false,
        codeBindShow: false,
        isBuyNew: false,
        isBindBuy: false,
        checked: false,
        list: [],
        likelist: [],
        params: {
            page: 1,
            rows: 20
        },
        loading: false,
        finished: true,
        checkedNum: 0, // 选中数量
        totalPrice: 0, // 总价格
        payParams: {
            addressId: 0,
            note: "",
            list: [
            ],
            payWay: 0
        },
    };
  },

  mounted() {
    if (!getToken()) {
        // 去登录
        this.$router.push('../login/index')
    } else {
        this.getCartList()
        this.getLikeList()
        this.getAddress()
    }
  },

  methods: {
    // 刷新 银行卡列表
    refreshListBind() {
        this.$refs.buy.getListBind()
    },
    // 获取默认地址
    getAddress() {
        getMrAddress().then(res => {
            this.payParams.addressId = res.data.id
        })
    },
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.params.page += 1
        this.loading = true
        likeList(this.params).then(res => {
          this.likelist = this.likelist.concat(res.data.list)
            // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (res.data.list.length !== this.params.rows) {
            this.finished = true
          }
        })
      }
    },
    // 获取购物车列表
    getCartList() {
        var that = this
        cartList().then(res => {
            that.list = res.data
            that.list.forEach(item => {
                item.checked = false
            })
        })
    },
    // 获取猜你喜欢的列表
    getLikeList() {
        var that = this
        likeList(that.params).then(res => {
            this.likelist = res.data.list
            if (res.data.list.length == that.params.rows) {
                that.finished = false
            }
        })
    },
    // 勾选购物车商品
    changeVal(index) {
        var that = this
        that.list[index].checked = !that.list[index].checked
        if (that.list[index].checked) {
            that.checkedNum = that.list[index].number + that.checkedNum
            that.totalPrice = that.list[index].number*that.list[index].price + that.totalPrice
        } else {
            that.checkedNum = that.checkedNum - that.list[index].number
            that.totalPrice = that.totalPrice - that.list[index].number*that.list[index].price
        }
        that.$forceUpdate()
    },
    // 全选
    checkAll() {
        var that = this
        that.checkedNum = 0
        that.totalPrice = 0
        if (that.checked) {
            // 全选
            that.list.forEach((item, index) => {
                item.checked = true
                that.checkedNum += item.number
                that.totalPrice += item.number*item.price
            })
        } else {
            // 全部取消
            that.list.forEach((item, index) => {
                item.checked = false
            })
        }
    },
    // 跳转到详情
    goGoodDetails(id) {
        this.$router.push({
            name: `goodsdetail`,
            params: {
              id: id
            }
        })
    },
    
    // 立即购买
    doBuy() {
        var that = this
        that.list.forEach((item, index) => {
          if (item.checked) {
            that.payParams.list.push({
              cartId: item.id,
              skuId: item.skuId,
              num: item.number
            })
          }
        })
        if (that.payParams.list == 0) {
          return that.$layer.quicktips('请选择商品')
        }
        that.isBuyNew = true
        // 创建订单
        // createOrder(that.payParams).then(res => {
        //     if (res.state == 200) {
        //         // 开始支付
        //         var payData = {
        //             payWay: 0,
        //             orderId: res.data.orderId
        //         }
        //         orderPay(payData).then(ress => {
        //             if (ress.state == 200) {
        //                 window.open(ress.data.url)
        //             }
        //         })
        //     }
        // })
    }
  },
};
</script>

<style lang="less" scoped>
    .menu{
        width: 100vw;
        height: 66rem;
    }
  .goods-card {
    margin: 0;
    background-color: #FFF;
    width: calc(100% - 60rem);
  }

  .delete-button {
    height: 100%;
  }
  .shopping_s{
    display: flex;
    padding: 10rem 10rem;
    border-bottom: 1rem solid #F6F7FA;
    background: #FFF;
  }
  .van-card__title{
    font-size: 14rem;
    color: #000000;
    line-height: 20rem;
    max-height: 42rem;
  }
  .van-tag{
    padding: 2rem 6rem;
    margin-top: 4rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 11rem;
    color: #939297;
    margin-right: 6rem;
  }
  .van-card{
    padding-left: 0rem;
  }
  .van-card__thumb{
    width: 98rem;
    height: 98rem;
  }
  .van-checkbox{
    width: 60rem;
  }
  .van-checkbox__icon{
    margin: auto;
  }
  .van-card__price{
    font-family: DINPro, DINPro;
    font-weight: 500;
    font-size: 14rem;
    color: #000000;
  }
  .van-card__num{
    width: 30rem;
    height: 28rem;
    border-radius: 2rem;
    border: 1rem solid #EEEFF2;
    font-family: DINPro, DINPro;
    font-weight: 500;
    font-size: 15rem;
    text-align: center;
    line-height: 28rem;
    color: #000000;
  }
  .shopping_box{
    background: #F6F7FA;
  }
  .shop_title{
    height: 57rem;
    line-height: 57rem;
    text-align: center;
    .star_left{
        width: 8rem;
        height: 8rem;
        margin-left: 4rem;
    }
    .star_right{
        width: 8rem;
        height: 8rem;
        margin-right: 4rem;
    }
    span{
        padding: 0rem 10rem;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 800;
        font-size: 17rem;
        color: #333333;
    }
  }
  .bottom_list{
        padding-bottom: 60rem;
  }
  .lazy_box {
        padding-bottom: 0rem;
        margin: 0rem 2rem;
        column-count: 2;
        column-gap: 2rem;
        -moz-column-count:2; /* Firefox */
        -webkit-column-count:2; /* Safari and Chrome */
        .item {
            background: #fff;
            margin-bottom: 2rem;
            padding-bottom: 6rem;
            display: inline-block;
            img {
                width: 184rem;
                height: 184rem;
            }
            .intro{
                margin: 3rem 6rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: 14rem;
                color: #666666;
                line-height: 20rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .jiamu{
                display: flex;
                margin: 3rem 6rem;
                margin-top: 6rem;
                justify-content: space-between;
                align-items: center;
                .price{
                    font-family: DINPro, DINPro;
                    font-weight: 500;
                    font-size: 14rem;
                    color: #000000;
                }
                .stock{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 300;
                    font-size: 12rem;
                    color: #939297
                }
            }
        }
    }
    .operating{
        padding: 0rem 12rem;
        height: 60rem;
        width: calc(100vw - 24rem);
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 43rem;
        left: 0rem;
        .op_left{
            width: calc(100% - 110rem);
            display: flex;
            .p_num{
                font-family: DINPro, DINPro;
                font-weight: 500;
                font-size: 15rem;
                color: #000000;

            }
            .van-checkbox{
                font-size: 12rem;
            }
        }
        .op_right{
            display: inline-block;
            width: 107rem;
            height: 44rem;
            background: #E2BFB0;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15rem;
            color: #333333;
            line-height: 44rem;
            text-align: center;
        }
    }
</style>
